@import "../../../scss/variables";

section.portfolio_info {
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
            gap: 32px;
        }

        .left_info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            padding-right: 100px;
            @media only screen and (max-width:$tab) {
                padding-right:0px;
            }
            @media only screen and (max-width:$mobile){
                grid-template-columns: 1fr;
             gap:8px;
            }

            p {
                &.t_14 {
                    color: rgba($greyOverlay, 0.8);
                }
            }
        }

        .right_info {
            p {
                margin: 0px;
                margin-bottom: 16px;
            }
        }

    }
}