@import "../../../scss/variables";


section.services_slider {
    .four_grid {
        display: grid;
        grid-template-columns: 1.1fr 1fr 1fr 1fr;
        padding-left: 8px;
    }

    .content_wrap {
        position: relative;

        button.slick-next {
            display: none !important;
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(calc(-50% - 20px));
            z-index: 2;

            &::before {
                color: $greyOverlay;
                font-size: 24px;

            }
        }

        button.slick-prev {
            display: none !important;
            position: absolute;
            top: 50%;
            left: -10px;
            transform: translateY(calc(-50% - 20px));
            z-index: 2;

            &::before {
                color: $greyOverlay;
                font-size: 24px;

            }
        }

        .service_slide {
            cursor: pointer;

            .img_wrap {
                height: 250px;
                overflow: hidden;
                padding: 0px 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            p {
                padding: 8px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                transition: all 0.3s ease-in-out;

                svg {
                    font-size: 22px;
                }
            }

            &:hover {
                p {
                    color: $orange;
                    gap: 16px;
                }
            }
        }
    }
}