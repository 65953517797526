@import "../../scss/variables";

.page_layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    button.menuBtn {
        position: fixed;
        bottom: 48px;
        right: 48px;
        padding: 16px;
        z-index: 9999;
        display: none;
        background-color:$white;
        border:1px solid $orange;

        @media only screen and (max-width:$menuBreak ) {
            display: block;
        }

        svg {
            font-size: 24px;
            color:$orange;
        }
    }

}