@import "../../scss/variables";

.loader_card {
    height:70vh;
    width: 100%;
    position: relative;

    .outer {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        border-left: 4px solid $text;
        border-right: 4px solid $text;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;

        animation-name: outerAnim;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .inner {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border-top: 4px solid $orange;
        border-bottom: 4px solid $orange;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;

        animation-name: innerAnim;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

    }
}

@keyframes outerAnim {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes innerAnim {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}