@import "../.././../scss/variables";

section.service_lr{
    .content_wrap {
        display: grid;
        grid-template-columns: 0.6fr 1fr;
        gap:32px;

        h1 {
            max-width: 500px;
            width: 100%;
        }

       a{
        text-decoration: none;
        color:$orange;
        
       }

     
        @media only screen and (max-width:$tab){
            grid-template-columns: 1fr;
        }
    }
}