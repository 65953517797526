@import "../../scss/variables";

section.menu_screen {
    min-height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999999;
    width: 100%;
    padding: 0px;

    .img_cover {
        height: 80vh;
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 2;
        width: 40%;
        opacity: 0;
        animation: comesInView 0s ease-out;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;

        @media only screen and (max-width:$mobile) {
            display: none;
        }

        .layer {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background-color: $white;
            z-index: 3;
            transition: all 0.6s ease-in-out;
            animation: openView 0.6s ease-out;
            animation-delay: 0.8s;
            width: 100%;
            animation-fill-mode: forwards;
        }


        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .strip_grid {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        z-index: 1;

        .flap {
            animation: expandWidth 0.6s ease-out;
            background-color: $white;
        }
    }

    .content_wrap {
        min-height: 100vh;
        position: relative;
        z-index: 2;
        border: 1px solid transparent;

        button.close_btn {
            position: absolute;
            top: 48px;
            right: 48px;
            z-index: 3;
            border-style: none;
            background-color: transparent;
            padding: 0px;

            svg {
                font-size: 32px;
            }
        }


        .bi_grid {
            display: grid;
            grid-template-columns: 0.7fr 1.3fr;
            gap: 16px;
            margin-top: 20vh;

            .link_wrap {
                padding-left: 32px;
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                gap:16px;
                a {
                    text-decoration: none;
                    margin-left: 32px;
                    transition: all 0.3e ease-in-out;
                   text-align: left;
                   display: flex;
                   justify-content: flex-start;

                    h1 {
                        color:rgba($text, 0.6);
                        transition: all 0.3s ease-in-out;
                        transform-origin: bottom center;
                    }

                    &.active,
                    &:hover {
                        h1 {
                            color:$orange;
                        }
                    }
                }
            }
        }





    }
}


@keyframes expandWidth {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes comesInView {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes openView {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}