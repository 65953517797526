@import "../../scss/variables";
@import "../../scss/mixin";


section.error_page{
    .content_wrap{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        h1{
            margin-bottom:16px;
            @include font("NeueHaasDisplay-Regular", 150px, 160px, 400, $dark);
            @media only screen and (max-width:$tab){
                @include font("NeueHaasDisplay-Regular", 100px, 100px, 400, $dark);
            }
            @media only screen and (max-width:$mobile){
                @include font("NeueHaasDisplay-Regular", 80px, 80px, 400, $dark);
            }

        }
        h1, h3, p{
            text-align: center;
        }
        button{
            margin-top:24px;
        }
    }
}