@import "../../../scss/variables";

section.about_lrt {
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;

        h2.title_header{
            max-width: 500px;
            width: 100%;
        }

        p {
            max-width: 500px;
            width: 100%;
            margin-bottom: 16px;

            a {
                color: $orange;
                text-decoration: none;
            }
        }
        @media only screen and (max-width:$tab){
            grid-template-columns: 1fr;
            gap:32px;
        }
    }
}