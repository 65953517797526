@import "../../../scss/variables";

section.tri_sec_grid_info {
    .content_wrap {

        .title_wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 48px;

            h2.title_header {
                text-align: center;
                color: $orange;

                span {
                    height: 46px;
                    overflow: hidden;
                }
            }

            p {
                text-align: center;
                max-width: 800px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }


        .tri_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 32px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
            }

            .tri_card {
                padding: 24px;
                border-radius: 8px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                span.icon {
                    position: relative;
                    background-color: rgba($orange, 0.1);
                    display: inline-block;
                    place-items: center;
                    padding: 8px 16px;
                    border-radius: 8px;

                    img {
                        filter: invert(54%) sepia(32%) saturate(6672%) hue-rotate(339deg) brightness(101%) contrast(98%);
                        width:28px;
                        height:28px;
                        object-fit: contain;
                    }
                }

                h4 {
                    margin-top: 16px;
                    margin-bottom: 8px;
                }

                p {
                    margin-bottom: 16px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

            }
        }
    }
}