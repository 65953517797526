@import "../../../scss/variables";
@import "../../../scss/mixin";

section.service_title_strip {
    padding-top: 200px;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width:$mobile){
        padding-top:100px;
    }


    img.bg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }


    .content_wrap {
        z-index: 2;

        h1 {
            display:inline-block;
            color: $text;
            padding:0px 8px;
            transition: all 0.3s linear;
            letter-spacing: 1px;
            position: relative;
            z-index: 2;
            background-color: rgba($white, 0.5);            
        }


        .tab_wraps {
            margin-top: 48px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            position: relative;
            z-index: 9;
            background-color: $white;

            button {
                position: relative;
                z-index: 2;
                padding: 16px 12px;
                @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, rgba($dark, 0.7));
                border-style: none;
                background-color: transparent;

                @media only screen and (max-width:$laptop) {
                    padding: 8px;
                }

                span {
                    position: absolute;
                    width: 0%;
                    bottom: 8px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $dark;
                    height: 1px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover,
                &.active {
                    color: $dark;

                    span {
                        width: 90%;
                    }
                }
            }
        }
    }




}