@import "../../scss/variables";

span{
    transform-origin: bottom center;
}

button.orange_btn {
    text-transform: capitalize;
    justify-self: flex-start;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-style: none;
    background-color: transparent;
    gap: 50px;
    overflow: hidden;
    padding-right: 50px;
    transition: all 0.3s ease-in-out;
    transform-origin:bottom center;
    letter-spacing: 1px;

    svg {
        font-size: 24px;
        color: $orange;
    }

    span.left {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
    }

    span.right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
        opacity: 0.7;
    }


    .line {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 1px;
        background-color: rgba($orange, 0.4);
        transition: all 0.3s ease-in-out;
    }

    .filler {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 0%;
        height: 1px;
        background-color: $orange;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        padding-left: 34px;
        padding-right: 16px;

        .line {
            width: 0%;
        }

        .filler {
            width: 100%;
        }

        span.left {
            left: 0px;
        }

        span.right {
            right: -20px;
        }
    }

    &.dark {
        color: $white;

        svg {
            color: $white;
        }

        .line {
            background-color: rgba($white, 0.5);
        }

        .filler {
            background-color: $white;
        }

    }
}