@import "../../../scss/variables";

section.latest_one_shop {
    .content_wrap {

        h2.title_header{
            text-align: center;
            color: $orange;
        }

        p {
            max-width: 600px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .glimps_slide {
            border-radius: 16px;
            overflow: hidden;
            .img_wrap {
                position:relative;
                .overlay{
                    position:absolute;
                    top:0px;
                    left:0px;
                    background:linear-gradient(to top, rgba(0, 0, 0, 0.674),rgba(0, 0, 0, 0));
                    width:100%;
                    height:100%;
                    z-index:1;
                    padding:16px;
                    display:flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    p{
                        text-align: center;
                        color:$white;
                    }
                }
                img {
                    width: 100%;
                    height: 500px;
                    object-fit: cover;
                    z-index:-1;
                }
            }
        }

    }
}