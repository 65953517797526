@import "./mixin";
@import "./variables";


@font-face {
    font-family: 'NeueHaasDisplay-Thin';
    src: url('../assets/fonts/NeueHaasDisplayThin.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasDisplay-Regular';
    src: url('../assets/fonts/NeueHaasDisplayRegular.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasDisplay-Medium';
    src: url('../assets/fonts/NeueHaasDisplayMedium.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasDisplay-Bold';
    src: url('../assets/fonts/NeueHaasDisplayBold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasDisplay-Black';
    src: url('../assets/fonts/NeueHaasDisplayBlack.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

button {
    cursor: pointer;
    @include font("NeueHaasDisplay-Regular", 20px, 24px, 400, $dark);



    &.t_14 {
        @include font("NeueHaasDisplay-Regular", 14px, 18px, 400, $dark);
    }

    &.t_16 {
        @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, $dark);
    }

    &.orange_btn {
        @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, $orange);
    }
}

p {
    transform-origin: bottom center;
    margin: 0px;
    letter-spacing: 1px;
    @include font("NeueHaasDisplay-Regular", 18px, 30px, 400, $text);

    &.t_12 {
        @include font("NeueHaasDisplay-Regular", 12px, 16px, 400, $text);
    }

    &.t_14 {
        @include font("NeueHaasDisplay-Regular", 14px, 18px, 400, $text);
    }

    &.t_24 {
        @include font("NeueHaasDisplay-Regular", 24px, 34px, 400, $text);
    }
}

h1 {
    margin: 0px;
    @include font("NeueHaasDisplay-Regular", 48px, 58px, 400, $dark);
    transform-origin: bottom center;
}

h2 {
    margin: 0px;
    @include font("NeueHaasDisplay-Regular", 36px, 40px, 400, $dark);
    transform-origin: bottom center;
    &.title_header{
        @include font("NeueHaasDisplay-Regular", 48px, 58px, 400, $dark);
        transform-origin: bottom center;
    }
}

h3 {
    transform-origin: bottom center;
    margin: 0px;
    @include font("NeueHaasDisplay-Regular", 28px, 34px, 400, $dark);
}

h4 {
    transform-origin: bottom center;
    margin: 0px;
    @include font("NeueHaasDisplay-Regular", 24px, 28px, 400, $dark);
}

h5 {
    transform-origin: bottom center;
    margin: 0px;
    @include font("NeueHaasDisplay-Regular", 20px, 24px, 400, $dark);
}

@media only screen and (max-width:$desktop) {
    h1 {
        @include font("NeueHaasDisplay-Regular", 40px, 46px, 400, $dark);
    }
    h2 {       
        &.title_header{
            @include font("NeueHaasDisplay-Regular", 40px, 46px, 400, $dark);
        }
    }
}

@media only screen and (max-width:$laptop) {
    h1 {
        @include font("NeueHaasDisplay-Regular", 32px, 38px, 400, $dark);
    }
    h2 {       
        &.title_header{
            @include font("NeueHaasDisplay-Regular", 32px, 38px, 400, $dark);
        }
    }
}