@import "../../../scss/variables";
@import "../../../scss/mixin";

section.latest_home_landing {
    padding: 0px;
    overflow: hidden;


    .latest_landing_slide {
        position:relative;
        button.controle_btn {
            position: absolute;
            top: 50%;
            border-style: none;
            border-radius: 50%;
            display: grid;
            place-items: center;
            height: 48px;
            width: 48px;
            background-color: $white;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            transition:all 0.3s ease-in-out;
            svg {
                color: $orange;
                font-size:22px;
                transition:all 0.3s ease-in-out;
            }

            &.prev {
                left: 16px;
                transform: translateY(-50%);
            }

            &.next {
                right: 16px;
                transform: translateY(-50%);
            }
            &:hover{
                background-color: $orange;
                svg{
                    color:$white;
                }
            }
        }
        .landing_slide {
            position: absolute;
            height: 100vh;
            width: 100%;
            position: relative;

            img {
                height: 100vh;
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: -1;
            }

            .overlay {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: -1;
                background: linear-gradient(to top, rgb(0, 0, 0.03), rgba(255, 255, 0, 0));
            }

            .text_content {
                max-width:800px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 120px;

                h2.title_header{
                    color: $white;
                    text-align: center;
                }

                button {
                    padding: 16px 32px;
                    border-radius: 56px;
                    background-color: $orange;
                    border-style: none;
                    margin-top: 16px;
                    @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, $white);
                    letter-spacing: 1px;
                    transform-origin: bottom center;
                }
            }
        }

        ul.slick-dots {
            position: absolute;
            bottom: 32px;
            left: 50%;
            transform: translateX(-50%);

            li {
                margin: 0px;

                button {
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $white;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    transition: all 0.3s ease-in-out;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background-color: $orange;
                    }
                }
            }
        }
    }
}