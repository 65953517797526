@import "../../../scss/variables";

section.contact_landing {
    background-color: $white;
    overflow: hidden;

    .circle {
        position: absolute;
        top: 60px;
        left: -40px;
        height: 350px;
        width: 350px;
        border-radius: 50%;
        background-color: $yellow;

        @media only screen and (max-width:$laptop) {
            height: 250px;
            width: 250px;
            top:0px;
        }
    }

    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;
        position: relative;
        padding-top: 350px;

        @media only screen and (max-width:$laptop) {
            padding-top: 200px;
            grid-template-columns: 1fr;
            gap: 48px;
        }

        @media only screen and (max-width:$tab) {
            padding-top: 150px;
        }

        .info_side {
            display: flex;
            justify-content: center;

            .text {
                margin-top: 40px;
         

                p {
                    margin-bottom:8px;

                    a {
                        transition: all 0.3s ease-in-out;
                        cursor: pointer;
                        color:$text;
                        text-decoration: none;
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }
            @media only screen and (max-width:$laptop){
                justify-content:flex-start;
              .text{
                margin-top:0px;
                p{
                    margin-bottom:0px;
                }
              }
            }
        }
    }
}