@import "../../scss/variables";
@import "../../scss/typography";


header {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 9999;
    transform-origin: top center;
    transition:all 0.4s ease-in-out;

    .decta_grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 0px;
        position: relative;

        @media only screen and (max-width:$menuBreak) {
            display: none;
        }

        .bg_strip {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            background-color: $white;
            z-index: 0;
            transition: all 0.4s ease-in-out;
        }

        .square {
            position: relative;
            height: 90px;
            border-left: 1px solid $gridBorder;
            z-index: 1;
            display:grid;
            place-items:center;
            img.logo{               
               height:66px;
               object-fit: contain;
               filter: invert(54%) sepia(32%) saturate(6672%) hue-rotate(339deg) brightness(101%) contrast(98%);
            }

            button {
                margin: 0px;
                height: 100%;
                width: 100%;
                border-style: none;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                transition: all 0.3s ease-in-out;
                border-bottom: 1px solid transparent;
                position: relative;
                cursor: pointer;
                

                svg {
                    transition: all 0.3s ease-in-out;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -1px;
                    height: 1px;
                    width: 0px;
                    background-color: $dark;
                    transition: all 0.4s ease-in-out;
                }

                &:hover {
                    border-bottom: 1px solid $dark;

                    &:after {
                        width: 100%;
                    }
                }

                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
                &.white{
                    color:$orange;
                    svg{
                        color:$orange;
                    }
                }

                &.menubtn {
                    p {
                        position: relative;
                        transition: all 0.3s ease-in-out;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 2px;
                            left: 4px;
                            height: 1px;
                            width: 26px;
                            background-color: $dark;
                            transition: all 0.3s ease-in-out;
                        }


                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 2px;
                            left: 4px;
                            height: 1px;
                            width: 26px;
                            background-color: $dark;
                            transition: all 0.3s ease-in-out;
                        }

                        span {
                            opacity: 0;
                            transition: all 0.3s ease-in-out;
                        }
                    }

                    &:hover {
                        span {
                            opacity: 1;
                        }

                        p {
                            &::before {
                                left: -24px;
                                background-color: rgba($dark, 0.6);
                            }

                            &::after {
                                left: 100%;
                                background-color: rgba($dark, 0.6);
                            }
                        }
                    }
                }
            }
        }
    }

    .menu_dd {
        position: absolute;
        top: 90px;
        left: 0px;
        width: 100%;
        background-color: $white;
        transform-origin: top;
        transform: scaleY(0);
        transition: all 0.4s ease-in-out;

        @media only screen and (max-width:$menuBreak) {
            display: none;
        }

        .four_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 24px;
            max-width: 1446px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .link_card {
                overflow: hidden;
                transition: all 0.3s ease-in-out;

                .img_wrap {
                    height: 250px;
                    width: 100%;
                    overflow: hidden;
                    position: relative;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        min-width: 100%;
                        min-height: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease-in-out;

                        &.main {
                            opacity: 1;
                            transition: all 0.3s ease-in-out;
                        }

                        &.bg {
                            opacity: 0;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }

                p {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 4px;
                    margin-top: 12px;
                    transition: all 0.4s ease-in-out;
                }

                &:hover {
                    p {
                        gap: 8px;
                    }

                    .img_wrap {
                        img {
                            &.main {
                                opacity: 0;
                            }

                            &.bg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.active {
            transform: scaleY(1);
            padding: 32px 0px;
        }
    }

    // Small Screen Menu
    section.small_menu {
        display: none;

        @media only screen and (max-width:$menuBreak) {
            display: block;
        }

        .content_wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 80px;
img{
    height:60px;
    object-fit: contain;
    filter: invert(54%) sepia(32%) saturate(6672%) hue-rotate(339deg) brightness(101%) contrast(98%);
}

        }
    }
}