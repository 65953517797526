@import "../../scss/variables";
@import "../../scss/mixin";

footer {
    padding-top: 160px;
    padding-bottom: 140px;
    background-color: $footer;
    position: relative;
    z-index: 2;
    margin-top: auto;

    section.footer {
        .content_wrap {
            .grid_wrap {
                display: grid;
                grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
                gap: 16px;

                @media only screen and (max-width:$laptop) {
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 24px;
                }

                @media only screen and (max-width:$tab) {
                    grid-template-columns: 1fr 1fr;
                }

                @media only screen and (max-width:$mobile) {
                    grid-template-columns: 1fr;
                }

                .link_col {
                    img{
                        width:150px;
                        object-fit: contain;
                    }
                    h1{
                        color:$white;
                    }
                    p.title {
                        margin-bottom:16px;
                        color: $white;
                        font-weight: 500;
                        letter-spacing: 1px;
                        text-transform: capitalize;
                    }

                    a {
                        display: block;
                        @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, $white);
                        transition: all 0.3s ease-in-out;
                        opacity: 0.4;
                        cursor: pointer;
                        padding:6px 0px;
                        text-decoration: none;
                        text-transform: capitalize;

                        &:hover,
                        &.active {
                            color: $white;
                            opacity: 1;
                        }
                    }
                }
            }

            .lower_line {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 48px;
                flex-wrap: wrap;

                .social {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;

                    a {
                        height: 56px;
                        width: 56px;
                        border: 1px solid rgba($white, 0.2);
                        border-radius: 4px;
                        display: grid;
                        place-items: center;
                        transition: all 0.3s ease-in-out;

                        svg {
                            font-size: 24px;
                            color: $white;
                        }

                        &:hover {
                            border: 1px solid rgba($white, 0.4);
                        }
                    }
                }

                .companies {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 60px;
                    flex-wrap: wrap;
                }

            }

            .bottom_line {
                margin-top: 32px;
             

                p {
                    @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, $white);
                    opacity: 0.4;
                    letter-spacing: 1px;
                }


            }



        }
    }
}