@import "../../scss/variables";


section.img_title_strip {
    position: relative;
    overflow: hidden;
    height: 300px;

    @media only screen and (max-width:$tab) {
        height: 250px;
    }

    @media only screen and (max-width:$mobile) {
        height: 200px;
    }

    img.bg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        min-height: 400px;
        object-fit: cover;
        z-index: 1;
    }


    .content_wrap {
        position: relative;
        z-index: 2;
        height: 100%;

        h2.title_header {
            color: $white;
            text-transform: capitalize;
            letter-spacing: 1px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            color: $text;
            background-color: rgba($white, 0.5);
            padding: 0px 8px;

            svg {
                color: $text;
            }
        }
    }
}