@import "../../../scss/variables";

section.blogs {
    .content_wrap {
        padding-top: 80px;
        padding-bottom: 80px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
        overflow: hidden;

        @media only screen and (max-width:$laptop) {
            grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-width:650px) {
            grid-template-columns: 1fr;
            padding-top: 56px;
        }

        .blog_card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            cursor: pointer;
            background-color:$bgLight;

            @media only screen and (max-width:650px) {
                gap: 8px;
            }

            .img_wrap {
                height: 250px;
                overflow: hidden;

                img {
                    width: 100%;
                    object-fit: cover;
                    min-height: 100%;
                }
            }
            .text_view{
                padding:0px 24px 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
            }

        }
    }
}