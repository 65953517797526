@import "../../../scss/variables";
@import "../../../scss/mixin";

section.about_landing {
    background-color: $green;
    position: relative;
    height: 100vh;
    @media only screen and (max-width:$tab){
        height:auto;
    }

    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;
        height: 100%;

        .white_flap {
            height: 150px;
            width: 50%;
            background-color: $white;
            position: absolute;
            bottom: 0px;
            left: 0px;

            @media only screen and (max-width:$tab) {
                display: none;
            }

        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            align-self: stretch;
            padding-right: 100px;
            position: relative;

            @media only screen and (max-width:$mobile){
               margin-top:48px;
            }




            button {
                border-style: none;
                background-color: transparent;
                padding: 16px 16px 16px 0px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                background-color: $white;
                transition: all 0.3s ease-in-out;
                position: absolute;
                bottom: 45px;
                left: 0px;
                z-index: 9;
                @include font("NeueHaasDisplay-Regular", 16px, 20px, 400, $dark);

                svg {
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    svg {
                        transform: translateY(8px);
                    }
                }
            }
        }

        .img_wrap {
            overflow: hidden;

            img {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 50%;
                height: 100%;
                object-fit: cover;
                max-height: 100vh;
            }
        }

        @media only screen and (max-width:$laptop) {
            grid-template-columns: 1fr;
            gap: 48px;

            .text {
                padding-right: 0px;
                padding-top: 100px;
                height: auto;
                padding-bottom: 60px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-self: flex-start;

                button {
                    bottom: 0px;
                    background-color: transparent;
                }
            }

            .img_wrap {
                img {
                    position: relative;
                    width: 100%;


                }
            }
        }

        @media only screen and (max-width:$mobile) {

            .text {
                padding-top: 48px;
            }
        }
    }
}