@import "../../../scss/variables";

section.why_choose_us {
    background-color: $bgLight ;

    .content_wrap {

        h2.title_header {
            text-align: center;
            color: $orange;
            margin-bottom: 48px;
        }

        .marque_box {
            padding: 8px;

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: stretch;
                justify-content:flex-start;
                width: 150px;
                height: 100%;
                background-color: $white;
                height: 150px;
                padding: 16px;
                border-radius: 8px;

                p {
                    text-align: center;
                    font-size: 16px;
                    line-height: 20px;
                    margin-top:20px;
                }

                .icon_wrap {
                    position: relative;
                    width:100%;
                    display:flex;
                    justify-content: center;
                    margin-top:16px;
                    img {
                        filter: invert(54%) sepia(32%) saturate(6672%) hue-rotate(339deg) brightness(101%) contrast(98%);
                        width:28px;
                        height:28px;
                        object-fit: contain;
                    }

                    .shape {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: rgba($orange, 0.1);
                        width: 48px;
                        height: 48px;
                        border-radius: 4px;
                    }
                }

            }
        }
    }
}



