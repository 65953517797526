

$gridBorder:#00000017;
$white:#ffffff;
$dark:#1B1C21;
$text:#535967;
$pink:#f7b3a3;
$green:#c0e3d3;
$orange:#fc5d37;
$greyOverlay:#4e5b77;
$yellow:#f6ebc3;
$footer:#16171a;
$bgLight:#f8f9fa;



// Break Points
$bigDesktop:1500px;
$desktop:1250px;

$menuBreak:960px;

$laptop: 992px;
$tab: 767px;
$mobile: 479px;
