@import "../../../scss/variables";

section.service_list_sec {
    background-color: $white;
    overflow: hidden;

    .content_wrap {

        .portfolio_grid {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 32px;
            padding-top: 80px;
            padding-bottom: 80px;

            @media only screen and (max-width:$tab) {
                padding-top: 48px;
                padding-bottom: 48px;
            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr;
                gap: 24px;
            }

            @media only screen and (max-width:550px) {
                grid-template-columns: 1fr;
                gap: 24px;
            }

            .service_card {
                cursor: pointer;
                width: 100%;

                img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                }

                p {
                    padding-top: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 8px;
                    padding-right: 8px;
                    transition: all 0.3s ease-in-out;


                    svg {
                        font-size: 24px;
                    }
                }

                &:hover {
                    p {
                        padding-right: 0px;
                        color:$orange;
                    }
                }
            }
        }
    }
}