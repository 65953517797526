@import "../../../scss/variables";
@import "../../../scss/mixin";

section.testimonials {
    background-color: $white;

    .content_wrap {
        .slick-slider {
            button.slick-arrow {
                display: none !important;
            }
        }

        .feed_slide {
            
            .card_slide{
                min-height:450px;
                width:calc(100% - 16px);
                border-radius:24px;
                background-color: $green;
                display:flex;
                justify-content: center;
                align-items: stretch;
                height:100%;
                margin-left:auto;
                margin-right:auto;

                .info_wrap{
                    margin:24px 0px;
                    width:calc(100% - 48px);                    
                    .img_wrap{
                        height:60px;
                        width:60px;
                        border-radius: 50%;
                        background-color: $white;
                        margin-bottom:16px;
                        display:grid;
                        place-items: center;
                        overflow: hidden;
                        img{
                            height:100%;
                            width:100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                    h4{
                        @include font("NeueHaasDisplay-Regular", 22px, 26px, 400, $dark);
                    }
                }
            }
        }

    }
}