@import "../../scss/variables";

section.tri_navigate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px;
    margin: 0px;
    padding: 0px;
    transition: all 0.3s ease-in-out;


    &.a {
        grid-template-columns: 1.1fr 1fr 1fr;
    }

    &.b {
        grid-template-columns: 1fr 1.1fr 1fr;
    }

    &.c {
        grid-template-columns: 1fr 1fr 1.1fr;
    }



    .navigation_card {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:400px;
        padding: 24px;
        cursor:pointer;

        img {
            position: absolute;
            object-fit: cover;
            z-index: 0;
        }

        .overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba($greyOverlay, 0.6);

        }

        h2.title_header{
            text-align: center;
            position: relative;
            text-transform: capitalize;
            color: $white;
            position: relative;
            z-index: 3;
        }
    }

    @media only screen and (max-width:$tab) {
        grid-template-columns: 1fr;

        &.a {
            grid-template-columns: 1fr;
        }

        &.b {
            grid-template-columns: 1fr;
        }

        &.c {
            grid-template-columns: 1fr;
        }

        .navigation_card {
            height: 200px;
        }
    }

    @media only screen and (max-width:$mobile) {
        .navigation_card {
            height: 100px;
        }
    }
}