@import "../../../scss/variables";

section.services_slide {
    .content_wrap {

        .title{
            margin-bottom:60px;
            @media only screen and (max-width:$tab){
                margin-bottom:48px;
            }
            h2.title_header{
                text-align:center;
            }
        }

        .detail_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0px;

            .slide_part {
                padding-right: 150px;
                height: 700px;

                @media only screen and (max-width:$desktop) {
                    padding-right: 32px;
                }

                .slide_box {
                    position: relative;
                    height: 100%;

                    img {
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        transform-origin: right center;
                        z-index: 0;
                        object-fit: cover;

                        &.active {
                            z-index: 1;
                        }
                    }

                    .btn_wrap {
                        position: absolute;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        right: 0px;
                        bottom: 0px;
                        z-index: 4;

                        button {
                            padding: 16px 24px;
                            border-style: none;
                            background-color: $white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.3s ease-in-out;

                            &:nth-child(2) {
                                border-left: 1px solid $orange;
                            }

                            svg {
                                color: $orange;
                                transition: all 0.3s ease-in-out;
                            }

                            &:hover {
                                background-color: $orange;

                                svg {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

            }

            .text_part {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                h2.title_header{
                    color: $orange;
                }

                p {
                    max-width: 600px;
                    width: 100%;
                    margin-top: 16px;
                }
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 48px;

                .slide_part {
                    padding-right: 0px;
                    height: 500px;
                }
            }
        }


    }
}