@import "../../../scss/variables";

section.latest_one_shop {
    .content_wrap {

        h1 {
            text-align: center;
            color: $orange;
        }

        p {
            max-width: 800px!important;
            width:100%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .shop_slider {
            margin-top: 48px;
            position: relative;

            button.controle_btn {
                position: absolute;
                top: 50%;
                border-style: none;
                border-radius: 50%;
                display: grid;
                place-items: center;
                height: 48px;
                width: 48px;
                background-color: $white;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                transition: all 0.3s ease-in-out;

                svg {
                    color: $orange;
                    font-size: 22px;
                    transition: all 0.3s ease-in-out;
                }

                &.prev {
                    left: 0;
                    transform: translate(-50%, -50%);
                }

                &.next {
                    right: 0px;
                    transform: translate(50%, -50%);
                }

                &:hover {
                    background-color: $orange;

                    svg {
                        color: $white;
                    }
                }
            }

            .slick-slider {
                position: relative;



                button.slick-arrow {
                    display: none !important;
                }

                .slick-list {

                    .slick-track {

                        .slick-slide {

                            padding: 0px 8px;

                            .card_slide {
                                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                                border-radius: 8px;
                                transition: all 0.3s ease-in-out;

                                .text {
                                    padding: 16px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 8px;

                                    h5 {
                                        text-align: center;
                                    }

                                    p {
                                        text-align: center;
                                        display: -webkit-box;
                                        -webkit-line-clamp:2;
                                        -webkit-box-orient: vertical;  
                                        overflow: hidden;
                                        display: none;
                                        &:nth-child(1){
                                            display:block;
                                            text-align: center;
                                            display: -webkit-box;
                                            -webkit-line-clamp:2;
                                            -webkit-box-orient: vertical;  
                                            overflow: hidden;
                                        }
                                    }

                                    button {
                                        text-align: center;
                                    }
                                }

                                &:hover {
                                    box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
                                }
                            }
                        }
                    }
                }
            }


            .card_slide {
                overflow: hidden;

                .img_wrap {
                    height: 180px;
                    width: 100%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        min-height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}