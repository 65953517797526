@import "../../../scss/variables";
@import "../../../scss/mixin";

section.contact_info {
    background-color: $white;
    padding-top: 100px;
    overflow: hidden;

    @media only screen and (max-width:$tab) {
        padding-top: 48px;
    }

    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;

        @media only screen and (max-width:$tab) {
            display: flex;
            flex-direction: column;
        }

        .left {
            padding-right: 48px;

            @media only screen and (max-width:$tab) {
                padding-right: 0px;
            }

            p {
                margin-bottom: 48px;
                max-width: 450px;
                width: 100%;

                span {
                    color: $orange;
                }
            }
            h2.title{
                text-align: center;
                margin-bottom:48px;
                @include font("NeueHaasDisplay-Regular", 32px, 36px, 400, $dark);
            }

            form {
                max-width: 450px;
                width: 100%;
                position: relative;


                .form_field {
                    margin-bottom: 16px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    label {
                        margin-bottom: 8px;
                        @include font("NeueHaasDisplay-Regular", 16px, 22px, 400, $greyOverlay);
                    }

                    input,
                    textarea {
                        width: 100%;
                        @include font("NeueHaasDisplay-Regular", 16px, 22px, 400, $greyOverlay);
                        border: 1px solid rgba($greyOverlay, 0.5);
                        padding: 8px 12px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        transition: all 0.3s ease-in-out;

                        &::placeholder {
                            opacity: 0.6;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border: 1px solid $greyOverlay;
                        }
                    }

                    textarea {
                        height: 200px;
                    }

                }

                button {
                    padding: 8px 16px;
                    background-color: transparent;
                    border: 1px solid $orange;
                    transition: all 0.3s ease-in-out;
                    @include font("NeueHaasDisplay-Regular", 16px, 22px, 400, $orange);
                    border-radius: 4px;

                    &:hover {
                        color: $white;
                        background-color: $orange;
                    }
                }

            
            }
            .success_screen {
                min-height:200px;
                width: 100%;               
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap:8px;
                transition: all 0.3s ease-in-out;
                background-color: $bgLight;
                border:1px solid $orange;
                padding:24px;
                position:relative;
                h2.title_header {
                    color: $orange;
                    text-align: center;
                }
                p{
                    text-align: center;
                
                }
                button.close{
                    position:absolute;
                    top:16px;
                    right:16px;
                    background-color: transparent;
                    border:1px solid $orange;
                    transition: all 0.3s ease-in-out;
                    svg{
                        color:$orange;
                        font-size:22px;
                        transition: all 0.3s ease-in-out;
                    }
                    &:hover{
                        background-color: $orange;
                        svg{
                            color:$white;
                        }
                    }
                }

            }
        }

        .right {
            position: relative;
            overflow: visible;
            padding-bottom: 150px;

            @media only screen and (max-width:$tab) {
                display: none;
            }

            .img_wrap {
                width: 80%;
                height: 750px;
                position: relative;

                .circle {
                    position: absolute;
                    top: 100px;
                    right: 0px;
                    transform: translateX(50%);
                    height: 250px;
                    width: 250px;
                    border-radius: 50%;
                    background-color: $yellow;

                    @media only screen and (max-width:$bigDesktop) {
                        height: 200px;
                        width: 200px;
                    }

                    @media only screen and (max-width:$desktop) {
                        height: 150px;
                        width: 150px;
                    }
                }

                img.verticle {
                    max-width: 100%;
                    max-height: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform-origin: center center;
                }

                img.second {
                    position: absolute;
                    bottom: -150px;
                    right: -100px;
                    height: 350px;
                    width: 400px;
                    object-fit: cover;
                }
            }

        }
    }
}