.portfolio_detail {
    position: relative;
    height: 60vh;
overflow: hidden;
    img {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
    }
}