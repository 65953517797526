@import "../../scss/variables";
@import "../../scss/mixin";

section.home_cta{
    padding-top:80px;
    padding-bottom:80px;
    background-color: $white;
    position:relative;
    z-index:2;
    height:350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img{
        position:absolute;
        top:0px;
        left:0px;
        min-width:100%;
        max-height:100%;
        object-fit: cover;
        z-index:0;
    }
    .overlay{
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        background-color: rgba($greyOverlay, 0.5);
    }
 p{
    position:relative;
    z-index:3;
    margin-bottom:32px;
    text-align: center;
    &.title{
        @include font("NeueHaasDisplay-Regular", 20px, 24px, 400, $white);        
    }
    &.text{
        @include font("NeueHaasDisplay-Regular", 36px, 40px, 400, $white);
        max-width:600px;
        width:100%;
    }
 }


}

