@import "../../scss/variables";


section.title_strip {
    background-color: $greyOverlay;
    height:300px;
    overflow: hidden;


    .content_wrap {
        position: relative;
        z-index: 2;
        height:100%;
        h1 {
            color: $white;
            letter-spacing: 1px;
            position:absolute;
            top:50%;
            transform:translateY(-50%);           
            left:0px;
        }
    }
    @media only screen and (max-width:$tab){
        height:250px;
    }
    @media only screen and (max-width:$mobile){
        height:200px;
    }
  
}