@import "../../../scss/variables";

section.blog_info {
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
            gap: 32px;
        }

    

    }
}